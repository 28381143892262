<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="개정" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.limLicenseId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-1">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정번호"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <c-text
                      :editable="false"
                      label="개정일시"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-1">
                    <c-text
                      :editable="false"
                      label="개정자"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <c-moc 
                      :editable="editable"
                      :isSubmit="saveCallData"
                      :document.sync="data"
                      :documentId="data.limLicenseId"
                      documentTitle="limLicenseKindName"
                      mocRelatedTaskCd="RT00000030"
                      label="MOC번호"
                      name="sopMocId"
                      v-model="data.sopMocId"
                    />
                  </div>
                  <div class="col-4">
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="개정사유"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="margin-top: -18px !important;" id="revdiv">
                <c-table
                  ref="revision"
                  title="개정이력"
                  tableId="revision"
                  topBorderClass="topcolor-orange"
                  :columns="gridrev.columns"
                  :data="gridrev.data"
                  gridHeight="150px"
                  :columnSetting="false"
                  :expandAll="false"
                  :isFullScreen="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :filtering="false"
                  @rowClick="rowClickRev"
                >
                </c-table>
              </div> -->
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card class="cardClassDetailForm" title="인허가 정보">
            <template slot="card-select">
              <c-select
                :editable="!isRevision"
                v-if="popupParam.limLicenseId"
                :comboItems="gridrev.data"
                type="custom"
                typetext="개정이력"
                itemText="revisionNum"
                itemValue="limLicenseId"
                name="selectedlimLicenseId"
                label=""
                v-model="selectedlimLicenseId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable && popupParam.limLicenseId && !isRevision && isSaveable"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <c-btn
                  v-show="editable && popupParam.limLicenseId && isRevision && isSaveable"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                      <c-btn
                  v-if="editable && popupParam.limLicenseId && isDeletable"
                  label="삭제"
                  icon="remove"
                  @btnClicked="deleteData" />
                      <c-btn
                  v-if="editable && isSaveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  :required="true"
                  :afterIcon="editable && !disabled ? [
                    { name: 'search', click: true, callbackName: 'searchLicenseKindId', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeLicenseKindId', color: 'red' }
                  ] : null"
                  :editable="editable"
                  :disabled="disabled"
                  :readonly="true"
                  label="인허가종류"
                  name="limLicenseKindName"
                  v-model="data.limLicenseKindName"
                  @searchLicenseKindId="searchLicenseKindId"
                  @removeLicenseKindId="removeLicenseKindId">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="인허가번호"
                  name="licenseNo"
                  v-model="data.licenseNo">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="false"
                  label="관련법규"
                  name="relatedLawsName"
                  v-model="data.relatedLawsName">
                </c-text>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  :disabled="disabled"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-3">
                <c-datepicker
                  :editable="editable"
                  label="인허가시작일"
                  name="licenseStartDate"
                  v-model="data.licenseStartDate"
                />
              </div>
              <div class="col-3">
                <c-datepicker
                  :editable="editable"
                  label="인허가종료일"
                  name="licenseEndDate"
                  v-model="data.licenseEndDate"
                />
              </div>
              <div class="col-3">
                <c-dept
                  :editable="editable"
                  label="관련부서"
                  name="relatedDeptCd"
                  v-model="data.relatedDeptCd">
                </c-dept>
              </div>
              <div class="col-3">
                <c-field
                  :editable="editable"
                  :data="data"
                  type="user"
                  label="인허가담당자"
                  name="chargeUserId"
                  v-model="data.chargeUserId" />
              </div>
              <div class="col-8">
                <c-textarea
                  :editable="editable"
                  label="비고"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div>
              <div class="col-4">
                <c-select
                  :disabled="true"
                  codeGroupCd="LICENSE_CREATE_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="licenseCreateTypeCd"
                  label="인허가 등록 구분"
                  v-model="data.licenseCreateTypeCd"
                ></c-select>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-table
            ref="equipmentTable"
            title="인허가관련 설비"
            tableId="equipmentTable"
            :columns="gridequipment.columns"
            :data="gridequipment.data"
            :columnSetting="false"
            selection="multiple"
            rowKey="equipmentCd"
            :filtering="false"
            :usePaging="false"
            :hideBottom="true"
            :isFullScreen="false"
            :isExcelDown="false"
            gridHeight="278px"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addRowEquipment" />
                <c-btn label="삭제" v-if="editable&&gridequipment.data.length>0" icon="remove" @btnClicked="removeRowEquipment" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-table
            ref="materialTable"
            title="인허가관련 화학자재"
            tableId="materialTable"
            :columns="gridmaterial.columns"
            :data="gridmaterial.data"
            :columnSetting="false"
            selection="multiple"
            rowKey="mdmChemMaterialId"
            :filtering="false"
            :usePaging="false"
            :hideBottom="true"
            :isFullScreen="false"
            :isExcelDown="false"
            gridHeight="278px"
          >
            <!-- 버튼 영역 -->
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addRowMaterial" />
                <c-btn label="삭제" v-if="editable&&gridmaterial.data.length>0" icon="remove" @btnClicked="removeRowMaterial" />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:20px !important">
          <c-upload
            :attachInfo="attachInfoRel"
            :editable="editable && attachable"
            label="인허가 관련서류">
          </c-upload>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-upload
            :attachInfo="attachInfo"
            :editable="editable && attachable"
            label="인허가증 파일">
          </c-upload>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'license-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        limLicenseId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      saveCallData: '',
      selectedlimLicenseId: null,
      attachable: false,
      attachInfoRel: {
        isSubmit: '',
        taskClassCd: 'LICENSE_FILE_REL',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'LICENSE_FILE',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      saveUrl: transactionConfig.sop.lim.lincense.card.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      disabled: false,
      isSaveable: true,
      isDeletable: false,
      listEUrl: '',
      saveEUrl: '',
      deleteEUrl: '',
      listMUrl: '',
      saveMUrl: '',
      deleteMUrl: '',
      data: {
        limLicenseId: '',  // 인허가 일련번호
        plantCd: '',  // 사업장 코드
        licenseNo: '',  // 인허가증 번호
        relatedLawsCd: '',  // 관련법규
        relatedLawsName: '',  // 관련법규
        limLicenseKindId: '',  // 인허가종류 알련번호
        limLicenseKindName: '',  // 인허가종류 알련번호
        licenseStartDate: '',  // 인허가시작일
        licenseEndDate: '',  // 인허가종료일
        relatedDeptCd: '',  // 관련부서
        chargeUserId: '',  // 인허가담당자
        remark: '',  // 비고
        licenseCreateTypeCd: '',  // 인허가 등록 구분코드
        relLimLicenseReviewId: '',  // 검토후등록-인허가 검토 일련번호
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        sysRevision: '1',  // 시스템 개정번호
        groupId: '',  // 일련번호 키
        revisionNum: '',  // 개정번호
        revisionContent: '제정',  // 개정사유
        regDtStr: '',
        regUserName: '',
        sopMocId: '',  // 관련MOC일련번호
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      gridequipment: {
        columns: [
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비관리번호',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      gridmaterial: {
        columns: [
          {
            name: 'materialCd',
            field: 'materialCd',
            label: '자재코드',
            align: 'center',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: '개정번호',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            label: '개정사유',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            label: '개정일시',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            label: '개정자',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.lim.lincense.card.get.url;
      this.insertUrl = transactionConfig.sop.lim.lincense.card.insert.url;
      this.updateUrl = transactionConfig.sop.lim.lincense.card.update.url;
      this.deleteUrl = transactionConfig.sop.lim.lincense.card.delete.url;
      this.revlistUrl = selectConfig.sop.lim.lincense.card.list.url + '/revs';

      this.listEUrl = selectConfig.sop.lim.lincense.equipment.list.url;
      this.saveEUrl = transactionConfig.sop.lim.lincense.equipment.insert.url;
      this.deleteEUrl = transactionConfig.sop.lim.lincense.equipment.delete.url;
      this.listMUrl = selectConfig.sop.lim.lincense.material.list.url;
      this.saveMUrl = transactionConfig.sop.lim.lincense.material.insert.url;
      this.deleteMUrl = transactionConfig.sop.lim.lincense.material.delete.url;
      this.getData();
      this.getEquipments(this.popupParam.limLicenseId);
      this.getMaterials(this.popupParam.limLicenseId);
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.limLicenseId) {
        this.selectedlimLicenseId = this.popupParam.limLicenseId;
        this.getDetail(this.popupParam.limLicenseId, true);
        this.disabled = true;
      } else {
        this.data.licenseCreateTypeCd = 'LCTC000001'; //직접등록
        this.disabled = false;
        this.attachable = true;
      }
    },
    getDetail(_limLicenseId, _check) {
      this.$http.url = this.$format(this.detailUrl, _limLicenseId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.data.regDtStr = this.data.regDtStr.slice(0, 19)
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.isRevision = false;
        this.$set(this.attachInfo, 'taskKey', _limLicenseId)
        this.$set(this.attachInfoRel, 'taskKey', _limLicenseId)
        this.attachable = true;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.limLicenseId == this.popupParam.limLicenseId) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }

        // 작성자/인허가담당자/관련부서원만 수정가능
        if (_result.data.chargeUserId == this.$store.getters.user.userId || _result.data.relatedDeptCd == this.$store.getters.user.deptCd || _result.data.regUserId == this.$store.getters.user.userId) {
          this.isSaveable = true;
        } else {
          this.isSaveable = false;
        }
        // 작성자만 삭제가능
        if (_result.data.regUserId == this.$store.getters.user.userId) {
          this.isDeletable = true;
        } else {
          this.isDeletable = false;
        }
      },);
    },
    getEquipments(_limLicenseId) {
      if (_limLicenseId) {
        this.$http.url = this.$format(this.listEUrl, _limLicenseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridequipment.data = _result.data;
        },);
      }
    },
    getMaterials(_limLicenseId) {
      if (_limLicenseId) {
        this.$http.url = this.$format(this.listMUrl, _limLicenseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.gridmaterial.data = _result.data;
        },);
      }
    },
    saveData() {
      if (this.popupParam.limLicenseId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';
      if (this.isRevision) {
        saveMsg = '현재버전을 개정하여 저장하시겠습니까?';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인', 
            message: saveMsg,
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인', 
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.limLicenseId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.limLicenseId = result.data.limLicenseId;
      if (this.isRevision) {
        this.saveEquip();
        this.saveMaterial();
      }
      this.$set(this.attachInfo, 'taskKey', result.data.limLicenseId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.$set(this.attachInfoRel, 'taskKey', result.data.limLicenseId)
      this.$set(this.attachInfoRel, 'isSubmit', uid())
      // 첨부파일 셋팅
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.$set(this.attachInfoRel, 'beforeTaskKey', '')
      this.$set(this.attachInfoRel, 'isRev', false)
      
      this.saveCallData = uid();
      this.getData();
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
       // 첨부파일 셋팅
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.popupParam.limLicenseId)
        this.$set(this.attachInfo, 'isRev', true)
        this.$set(this.attachInfoRel, 'taskKey', '')
        this.$set(this.attachInfoRel, 'beforeTaskKey', this.popupParam.limLicenseId)
        this.$set(this.attachInfoRel, 'isRev', true)
      });
    },
    CancelRevision() {
      this.isRevision = false;
      // 첨부파일 셋팅
      this.$set(this.attachInfo, 'taskKey', this.popupParam.limLicenseId)
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.$set(this.attachInfoRel, 'taskKey', this.popupParam.limLicenseId)
      this.$set(this.attachInfoRel, 'beforeTaskKey', '')
      this.$set(this.attachInfoRel, 'isRev', false)
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        if (row != this.data.limLicenseId) {
          this.selectedlimLicenseId = row;
          this.getDetail(this.selectedlimLicenseId, false);
          this.getEquipments(this.selectedlimLicenseId);
          this.getMaterials(this.selectedlimLicenseId);
        }
      }
    },
    searchMoc() {
      this.popupOptions.title = 'MOC 검색';
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMocPopup;
    },
    closeMocPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data) {
        this.data.sopMocId = data[0].sopMocId
      }
    },
    removeMoc() {
      this.data.sopMocId = null
    },
    searchLicenseKindId() {
      this.popupOptions.title = '관련법규/인허가종류 검색';
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.data.plantCd, // 사업장번호를 넘기면 해당사업장의 인허가현황에 포함된 인허가는 조회되지 않음
      };
      this.popupOptions.target = () => import(`${'@/pages/common/license/licenseKindPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLicenseKindId;
    },
    closeLicenseKindId(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.data.relatedLawsName = data[0].relatedLawsName;
        this.data.relatedLawsCd = data[0].relatedLawsCd;
        this.data.limLicenseKindId = data[0].limLicenseKindId;
        this.data.limLicenseKindName = data[0].limLicenseKindName;
      }
    },
    removeLicenseKindId() {
      this.data.relatedLawsCd = '';
      this.data.relatedLawsName = '';
      this.data.limLicenseKindId = '';
      this.data.limLicenseKindName = '';
    },
    addRowEquipment() {
      this.popupOptions.title = '인허가관련 설비 추가'; 
      this.popupOptions.param = {
        type: 'multiple',
        title: '인허가관련 설비 추가'   
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddRow1;
    },
    closeAddRow1(_data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if(_data) {
        let saveData = [];
        this.$_.forEach(_data, item => {
          if (this.gridequipment.data.map(function(e) {return e.equipmentCd;}).indexOf(item.equipmentCd) < 0) {
            saveData.push({
              limLicenseId: this.popupParam.limLicenseId,
              equipmentCd: item.equipmentCd,
              equipmentNo: '',
              equipmentName: '',
              equipmentTypeName: '',
            })
          }
        });
        this.$http.url = this.saveEUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '저장되었습니다.', 
            type: 'success', // success / info / warning / error
          });
          this.getEquipments(this.popupParam.limLicenseId);
        });
      }
    },
    saveEquip() {
      let saveData = this.gridequipment.data;
      this.$_.forEach(saveData, item => {
        item.limLicenseId = this.popupParam.limLicenseId;
      });
      this.$http.url = this.saveEUrl;
      this.$http.type = 'POST';
      this.$http.param = saveData;
      this.$http.request(() => {
        this.getEquipments(this.popupParam.limLicenseId);
      });
    },
    removeRowEquipment() {
      let selectData = this.$refs['equipmentTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteEUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getEquipments(this.popupParam.limLicenseId);
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '삭제되었습니다.',
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addRowMaterial() {
      this.popupOptions.title = '인허가관련 화학자재 추가'; 
      this.popupOptions.param = {
        type: 'multiple',
        title: '인허가관련 화학자재 추가' 
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/chemMaterialPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddRow2;
    },
    closeAddRow2(_data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if(_data) {
        let saveData = [];
        this.$_.forEach(_data, item => {
          if (this.gridmaterial.data.map(function(e) {return e.mdmChemMaterialId;}).indexOf(item.mdmChemMaterialId) < 0) {
            saveData.push({
              limLicenseId: this.popupParam.limLicenseId,
              mdmChemMaterialId: item.mdmChemMaterialId,
              materialCd: '',
              materialName: '',
            })
          }
        });
        this.$http.url = this.saveMUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '저장되었습니다.', 
            type: 'success', // success / info / warning / error
          });
          this.getMaterials(this.popupParam.limLicenseId);
        });
      }
    },
    saveMaterial() {
      let saveData = this.gridmaterial.data;
      this.$_.forEach(saveData, item => {
        item.limLicenseId = this.popupParam.limLicenseId;
      });
      this.$http.url = this.saveMUrl;
      this.$http.type = 'POST';
      this.$http.param = saveData;
      this.$http.request(() => {
        this.getMaterials(this.popupParam.limLicenseId);
      });
    },
    removeRowMaterial() {
      let selectData = this.$refs['materialTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteMUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getMaterials(this.popupParam.limLicenseId);
              window.getApp.$emit('ALERT', {
                title: '안내', 
                message: '삭제되었습니다.',
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
